import axios from "axios";

export default {
    methods: {
        $post(url, data, config) {
            var formHeader = "";
            
            if (data instanceof FormData) {
                formHeader = {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'multipart/form-data',
                    'x-access-token' : localStorage.getItem('x-access-token')
                };
                
            }else {
                formHeader = {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json; charset = utf-8',
                    'x-access-token' : localStorage.getItem('x-access-token')
                };

                data = JSON.stringify(data);
            }
            
            if (config == null || config == "undefined")  {
                config = {
                    headers: formHeader
                }
            }

            return (axios.post(url, data, config).then(
                response => {
                    if (response.data.code == 100) {
                        localStorage.removeItem("x-access-token");
                        window.location.href = "/login/";
                        return;
                    }
                    return response.data;
                }
            )
            .catch(
                e => {
                    return e;
                }
            ));
        },

        async $postFile(url, data, config) {
            if (config == null || config == "undefined")  {
                config = {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json; charset = utf-8',
                        'x-access-token' : localStorage.getItem('x-access-token')
                    }, 
                    responseType : "blob"
                }
            }
            
            return await (axios.post(url, JSON.stringify(data), config).then(
                response => {
                    if (response.data.code == 100) {
                        localStorage.removeItem("x-access-token");
                        window.location.href = "/login/";
                        return;
                    }
                    return response;
                }
            )
            .catch(
                e => {
                    return e;
                }
            ));
        },

        async $get(url, data, config) {
            if (config == null || config == "undefined")  {
                config = {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json; charset = utf-8',
                        'x-access-token' : localStorage.getItem('x-access-token')
                    }
                }
            }
            
            return await (axios.get(url, data, config).then(
                response => {
                    return response.data;
                }
            )
            .catch(
                e => {
                    return e;
                }
            ));
        },

        $checkLogin : async function(_goUrl) {
            let response = await this.$post("/stock/checkLogin");
            if (response) {
                switch (response.code) {
                    case 0:
                        if (_goUrl) {
                            window.location.href = "/";
                        }
                        return response.data;
                    default:
                        alert("error = " + response.message );
                        localStorage.removeItem("x-access-token");
                    break;
                }
            }
            return "";
        },

        $getCategory : function() {
            var data = {
                type : "A"
            };

            this.$post('/public/getCategory', data)
            .then(response => {
                localStorage.setItem("category", JSON.stringify(response.data));
            })
            .catch(e => {
                this.$log("error = " + e);
            })
        },
        $getStockCode : async function() {
            var data = {
                type : "1"
            };

            await this.$post('/public/getStockItem', data).then(response => {
                var stockName = "";
                if (response) {
                    switch (response.code) {
                    case 1:
                        alert("getStockItem error = " + response.message );
                        this.confirmMessage = response.message;
                        localStorage.removeItem("x-access-token");
                    break;
                    case 0:
                        //console.dir("getStockItem : " + response);
                        for (var value of response.data) { 
                            stockName += value.stockName + ";";
                        }
                        localStorage.setItem("stockName", stockName); 
                        localStorage.setItem("stockItem", JSON.stringify(response.data));
                    break;
                    }
                }
            }).catch(e => {
                this.$log( "getStockItem : " + e);
                localStorage.removeItem("x-access-token");
            });
        },

        $getThemeList :  async function() {
            try {
                 let response = await this.$post('/stock/getThemeList');
                 localStorage.setItem("themeList", JSON.stringify(response.data)); 
                 return response.data;
            }catch(e) {
                this.$log("error = " + e);
            }
        },
        $getFormatDate : function(strDate) {
            const date = new Date(strDate);
            var year = date.getFullYear();
            var month = (1 + date.getMonth());
            month = month >= 10 ? month : "0" + month;
            var day = date.getDate();
            day = day >= 10 ? day : '0'+day;

            return year + "-" + month + "-" + day;
        },
        $changeImportant: async function(_uid, _important) {
            let newData = {
                "UID" : _uid,
                "important" : _important,
                "modifiedStatus" : 0x80
            };
            let updateData = {
                stockData : newData
            }

            try {
                let response = await this.$post('/stock/updateFeed', updateData);
                if (response) {
                    switch (response.code) {
                        case 0:
                            this.$toastMessage(true, "중요도 설정 완료");
                        break;
                        default:
                            this.$toastMessage(false, "ERROR  ="+response.message);
                        break;
                    }
                    return response;
                }
            }catch(e) {
                this.$log( "중요도 설정 : " + e);
            }
       },  
    }
}