import { createRouter, createWebHistory } from 'vue-router'

const routes = [
	{
		path: '/sibusCBT',
		name: 'sibusCBT',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "about" */ '../views/siBusCBT.vue')
	},

	{
		path: '/liveJoin',
		name: 'liveJoin',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "about" */ '../views/LiveJoin.vue')
	},

	//만담채널 입장 확인
	{
		path: '/bookchannelJoin',
		name: 'bookchannelJoin',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "about" */ '../views/bookchannelJoin.vue')
	},

	//만담채널 입장 확인
	{
		path: '/bookchannelExist',
		name: 'bookchannelExist',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "about" */ '../views/bookchannelJoinExist.vue')
	},
	

	//만담채널 입장 확인
	{
		path: '/bookchannelJoinTest',
		name: 'bookchannelJoinTest',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "about" */ '../views/bookchannelJoinTest.vue')
	},

	{
		path: '/calcAddressList',
		name: 'calcAddressList',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "about" */ '../views/calcAddress.vue')
	},

	{
		path: '/freeWebinar',
		name: 'freeWebinar',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "about" */ '../views/freeWebinar.vue')
	},

	{
		path: '/event2303A',
		name: 'selectEventLookUp',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "about" */ '../views/selectEventLookUp.vue')
	},

	{
		path: '/event2305C',
		name: 'challengerEventLookUp',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		//component: () => import(/* webpackChunkName: "about" */ '../views/challengerEventLookUp.vue')
		component: () => import(/* webpackChunkName: "about" */ '../views/challengerEventCheckUser.vue')
	},
	{
		path: '/event2307P',
		name: 'thefirsthalfEvent',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		//component: () => import(/* webpackChunkName: "about" */ '../views/challengerEventLookUp.vue')
		component: () => import(/* webpackChunkName: "about" */ '../views/thefirsthalfEvent.vue')
	},


	{
		path: '/testTossPass',
		name: 'testTossPass',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		//component: () => import(/* webpackChunkName: "about" */ '../views/challengerEventLookUp.vue')
		component: () => import(/* webpackChunkName: "about" */ '../views/testTossPass.vue')
	},
	{
		path: '/',
		name: 'HOME',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
	}
  ]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

export default router
