import { createApp, reactive } from 'vue'
import App from './App.vue'
import router from './router'
import common from './service/common.js'
import 'primevue/resources/primevue.min.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import myMixin from './service/api.js'

import PrimeVue from 'primevue/config';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import FileUpload from 'primevue/fileupload';

import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';

import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmPopup from 'primevue/confirmpopup';
import ConfirmationService from 'primevue/confirmationservice';
import RadioButton from 'primevue/radiobutton';
import CheckBox from 'primevue/checkbox';
import Dialog from 'primevue/dialog';
import ProgressSpinner from 'primevue/progressspinner';
import Calendar from 'primevue/calendar';
import Textarea from 'primevue/textarea';
import Dropdown from 'primevue/dropdown';



const app = createApp(App);

app.config.globalProperties.$appState = reactive({ theme: 'saga-blue' });
app.mixin(myMixin);
app.mixin(common);

app.use(PrimeVue, { ripple: true, inputStyle: 'outlined' });
app.use(ConfirmationService);
app.use(ToastService);

app.component('InputText', InputText);
app.component('Button', Button);
app.component('FileUpload', FileUpload);

app.component('ConfirmDialog', ConfirmDialog);
app.component('ConfirmPopup', ConfirmPopup);
app.component('RadioButton', RadioButton);
app.component('CheckBox', CheckBox);
app.component('Toast', Toast);
app.component('Dialog', Dialog);
app.component('ProgressSpinner', ProgressSpinner);
app.component('Calendar', Calendar);
app.component('Textarea', Textarea);
app.component('Dropdown', Dropdown);

app.use(router);

app.mount('#app');


